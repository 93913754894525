/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const gtmVirtualPageView = (rest) => {
  window.dataLayer?.push({
    event: "VirtualPageView",
    ...rest,
  });
};
