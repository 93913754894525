/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-irregular-whitespace */
import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { AppProps } from "next/app";
import { HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LogRocket from "logrocket";
import { Button } from "@nextui-org/react";
import "../styles/globals.css";
import "@aws-amplify/ui-react/styles.css";
import "yet-another-react-lightbox/styles.css";
import { Loader } from "components/Loader";
import Script from "next/script";
import { MainLayout } from "../components/Layout/MainLayout";
import { gtmVirtualPageView } from "../utils/gtm";
import amplifyconfig from "../src/amplifyconfiguration.json";

if (process.env.NEXT_PUBLIC_ENVIRONMENT === "dev") {
  // Amplify.Logger.LOG_LEVEL = "DEBUG";
}
if (process.env.NEXT_PUBLIC_ENVIRONMENT === "prod") {
  LogRocket.init("s8guzh/raraity");
}

if (process.env.NEXT_PUBLIC_ENVIRONMENT === "prod") {
  amplifyconfig.oauth.domain = "prod.auth.raraity.com";
}

Amplify.configure(amplifyconfig, { ssr: true });

const queryClient = new QueryClient();

function ErrorFallback() {
  const router = useRouter();
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => router.reload()}>
        Refresh
      </Button>
    </div>
  );
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const mainDataLayer = {
      pageTypeName: pageProps.page || null,
      url: router.pathname,
    };

    gtmVirtualPageView(mainDataLayer);
  }, [pageProps, router.pathname]);

  return (
    <>
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Loader />
          </div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <MainLayout>
                {/* Google Tag Manager */}
                <Script
                  async
                  id="GTM"
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM}');`,
                  }}
                />
                {/* End Google Tag Manager */}
                <Component {...pageProps} />
              </MainLayout>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </React.Suspense>
    </>
  );
}

export default MyApp;
