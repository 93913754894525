/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import dynamic from "next/dynamic";

const Navbar = dynamic(() => import("./Navbar"));
type MainLayoutProps = {
  children: React.ReactNode;
};

export function MainLayout({ children }: MainLayoutProps) {
  return (
    <>
      <Authenticator.Provider>
        <Navbar />
        <main className="flex-1">{children}</main>
      </Authenticator.Provider>
    </>
  );
}

export default MainLayout;
